import APIClientLand from "../APIClientLand";

export const getDataJurnal = async () => {
  //const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClientLand.get(`/api/jurnal`).then((res) => res);
  return result;
};

export const getDataChannel = async () => {
  //const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClientLand.get(`/api/content/type`).then(
    (res) => res
  );
  return result;
};

export const sendComplaint = async (formData) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const result = await APIClientLand.post(
    `/api/complaint`,
    formData,
    config
  ).then((res) => res);
  return result;
};

export const getDataImage = async () => {
  //const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClientLand.get(`/api/content/1`).then((res) => res);
  return result;
};
export const getDataImageNakes = async () => {
  //const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClientLand.get(`/api/content/2`).then((res) => res);
  return result;
};

export const getDataEvent = async () => {
  //const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClientLand.get(`/api/event`).then((res) => res);
  return result;
};
