import React, { useState, useEffect } from "react";
import axios from "axios";
import Full from "../../load/Laoding";
import {
  getListComplaint,
  updateStatusComplaint,
} from "../../service/admin/AdminService";
import ModalArsip from "../admin/modal/ModalArsip";
import Swal from "sweetalert2";

const MonitorPengaduan = () => {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [dataComplaint, setDataComplaint] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idEvent, setIdEvent] = useState("");

  useEffect(() => {
    GetDataComplaint();
  }, []);

  const GetDataComplaint = () => {
    showLoader();
    getListComplaint()
      .then((res) => {
        setDataComplaint(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          window.location = "/admin-sitapic";
          localStorage.removeItem("token");
          hideLoader();
        } else {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        }
      });
  };

  const DoneComplain = (noComplaint, idComplaint) => {
    Swal.fire({
      title: `Anda Yakin Nomor Komplain ${noComplaint} Sudah Diatasi ?`,
      text: `Jika sudah selesai, maka masalah tersebut akan hilang dari daftar tabel.`,
      showDenyButton: true,
      icon: "question",
      confirmButtonColor: "#1E6C93",
      cancelButtonColor: "#1E6C93",
      confirmButtonText: "Yakin",
      denyButtonText: `Belum`,
      customClass: {
        confirmButton: "btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader();
        updateStatusComplaint(idComplaint)
          .then((res) => {
            Swal.fire({
              title: res.data.message,
              showConfirmButton: false,
              icon: "success",
            });
            window.location.reload();
            hideLoader();
          })
          .catch((err) => {
            if (err.response.data.message == "Expired token") {
              Swal.fire({
                title: err.response.data.message,
                showConfirmButton: false,
                icon: "warning",
              });
              window.location = "/admin-sitapic";
              localStorage.removeItem("token");
              hideLoader();
            } else {
              Swal.fire({
                title: err.response.data.message,
                showConfirmButton: false,
                icon: "warning",
              });
              window.location.reload();
              hideLoader();
            }
          });
      }
    });
  };

  useEffect(() => {
    if (dataComplaint.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(function () {
      $("#example1")
        .DataTable({
          responsive: false,
          info: false,
          lengthChange: false,
        })
        .columns.adjust()
        .responsive.recalc();
    });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [dataComplaint]);

  const handleDownload = async (id, nomor, ext) => {
    try {
      // Assuming you have the token stored in local storage or in a state
      const token = localStorage.getItem("token");

      // Set up the request headers with the authorization token
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Make the API call to download the file
      const response = await axios.get(
        `https://be.sitapic.com/admin/public/api/complaint/file/${id}`,
        {
          headers: headers,
          responseType: "blob", // Important for downloading files
        }
      );

      // Create a URL for the blob and set up the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Pengaduan-${nomor}.${ext}`); // You can set the file name dynamically if needed
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the DOM by removing the temporary link
    } catch (error) {
      console.error("Error downloading the file:", error);
      // Optionally, display an error message to the user
    }
  };

  const openModalArsip = () => {
    setModalIsOpen(true);
    setIdEvent(null);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div className="mt-6">
        <h5 className="lg:text-[30px] text-md font-inter font-bold text-black">
          Monitoring Layanan Pengaduan
        </h5>
        <div className="grid grid-cols-1 gap-2 lg:pb-4 ">
          <div className="col-span-1 flex justify-end items-center">
            {/* Button aligned to the right in the second column */}
            <button
              onClick={openModalArsip}
              className="bg-[#0368DE] hover:opacity-80 text-white font-bold py-2 px-4 rounded text-sm lg:text-md"
            >
              Arsip Pengaduan
            </button>
          </div>
        </div>
        <div
          id="recipients"
          class="p-8 mt-6 lg:mt-0 rounded-xl shadow-xl bg-white border border-slate-100"
        >
          <div className="overflow-x-auto relative">
            <table
              id="example1"
              className="stripe hover text-sm lg:text-md"
              style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}
            >
              <thead className="bg-primary text-white">
                <tr>
                  <th
                    data-priority={1}
                    rowSpan={2}
                    className="sticky left-0 bg-primary"
                  >
                    NO.
                  </th>
                  <th
                    data-priority={2}
                    rowSpan={2}
                    className="sticky left-12 bg-primary"
                  >
                    NO.KOMPLAIN
                  </th>
                  <th data-priority={3} rowSpan={2}>
                    EMAIL
                  </th>
                  <th data-priority={4} rowSpan={2}>
                    NAMA DOKTER
                  </th>
                  <th data-priority={4} colSpan={3}>
                    TEMPAT PRAKTEK
                  </th>
                  <th data-priority={5} rowSpan={2}>
                    DESKRIPSI
                  </th>
                  <th data-priority={6} rowSpan={2}>
                    TANGGAL INPUT
                  </th>
                  <th data-priority={6} rowSpan={2}>
                    BERKAS UNGGAH
                  </th>
                  <th data-priority={6} rowSpan={2}>
                    AKSI
                  </th>
                </tr>
                <tr>
                  <th data-priority={1}>JENIS</th>
                  <th data-priority={2}>NAMA</th>
                  <th data-priority={3}>ALAMAT</th>
                </tr>
              </thead>
              <tbody>
                {dataComplaint.length === 0 ? (
                  <tr className="text-center font-inter ">
                    <td colSpan={"11"} className="py-6 text-lg">
                      {" "}
                      Data Belum Ada
                    </td>
                  </tr>
                ) : (
                  <>
                    {dataComplaint?.map((list, index) => {
                      return (
                        <tr className="text-center" key={index}>
                          <td className="sticky left-0 bg-white">
                            {index + 1}.
                          </td>
                          <td className="sticky left-12 bg-white">
                            {list.nomor}
                          </td>
                          <td>{list.email}</td>
                          <td>{list.person_name}</td>
                          <td>{list.channel_type_name}</td>
                          <td>{list.location_name}</td>
                          <td>{list.address_name}</td>
                          <td>{list.description}</td>
                          <td>{list.complaint_date}</td>
                          <td>
                            <button
                              onClick={() =>
                                handleDownload(list.id, list.nomor, list.ext)
                              }
                              className="bg-[#A14A76] hover:opacity-70 text-white text-[12px] lg:text-[15px] font-light py-1 px-1 md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-lg"
                            >
                              Unduh
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => DoneComplain(list.nomor, list.id)}
                              className="bg-[#4AA16D] hover:opacity-70 text-white text-[12px] lg:text-[15px] font-light py-1 px-1  md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-lg"
                            >
                              Selesai
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {loader}
      {modalIsOpen ? (
        <ModalArsip onCloseModal={closeModal} idEvent={idEvent} />
      ) : null}
    </>
  );
};

export default MonitorPengaduan;
