import React, { useState, useEffect } from "react";
import Full from "../../load/Laoding";
import { getListContent } from "../../service/admin/AdminService";
import ModalEditContent from "../admin/modal/ModalEditContent";
import Swal from "sweetalert2";

const MonitorLanding = () => {
  const [loader, showLoader, hideLoader] = Full();

  const [dataContent, setDataContent] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idContent, setIdContent] = useState("");

  useEffect(() => {
    GetDataContent();
  }, []);

  const GetDataContent = () => {
    showLoader();
    getListContent()
      .then((res) => {
        setDataContent(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          window.location = "/admin-sitapic";
          localStorage.removeItem("token");
          hideLoader();
        } else {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        }
      });
  };

  const openModalAEditContent = (id) => {
    setModalIsOpen(true);
    setIdContent(id);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (dataContent.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(function () {
      $("#example4")
        .DataTable({
          responsive: false,
          info: false,
          lengthChange: false,
          paging: false,
        })
        .columns.adjust()
        .responsive.recalc();
    });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [dataContent]);

  return (
    <>
      <div className="mt-12">
        <h5 className="lg:text-[30px] text-md font-inter font-bold text-primary mb-6">
          Konten Landing Page
        </h5>
        <div
          id="recipients"
          class="p-8 mt-6 lg:mt-0 rounded-xl shadow-xl bg-white border border-slate-100"
        >
          <div className="overflow-x-auto relative">
            <table
              id="example4"
              className="stripe hover text-sm lg:text-md"
              style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}
            >
              <thead className="bg-primary text-white">
                <tr>
                  <th data-priority={1} className="sticky left-0 bg-primary">
                    NO.
                  </th>
                  <th data-priority={2} className="sticky left-12 bg-primary">
                    KONTEN
                  </th>
                  <th data-priority={3}>GAMBAR</th>
                  <th data-priority={4}>HALAMAN</th>
                  <th data-priority={5}>TERAKHIR PERBARUI</th>
                  <th data-priority={6}>AKSI</th>
                </tr>
              </thead>
              <tbody>
                {dataContent.length === 0 ? (
                  <tr className="text-center font-inter ">
                    <td colSpan={"6"} className="py-6 text-lg">
                      {" "}
                      Data Belum Ada
                    </td>
                  </tr>
                ) : (
                  <>
                    {" "}
                    {dataContent?.map((list, index) => {
                      return (
                        <tr className="text-center" key={index}>
                          <td className="sticky left-0 bg-white">
                            {index + 1}.
                          </td>
                          <td className="sticky left-12 bg-white">
                            {list.content_name}
                          </td>
                          <td>{list.file_name}</td>
                          <td>{list.page}</td>
                          <td>
                            {list.update_at === null ? (
                              <span>-</span>
                            ) : (
                              <span>{list.update_at}</span>
                            )}
                          </td>
                          <td>
                            <button
                              onClick={() => openModalAEditContent(list.id)}
                              className="bg-[#A14A76] hover:opacity-70 text-white text-[12px] lg:text-[15px] font-light py-1 px-1  md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-lg "
                            >
                              Perbarui
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {modalIsOpen ? (
        <ModalEditContent onCloseModal={closeModal} idContent={idContent} />
      ) : null}
    </>
  );
};

export default MonitorLanding;
