import { useLocation, Link, Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
const home = <FontAwesomeIcon icon={faHome} />;
const log = <FontAwesomeIcon icon={faRightFromBracket} />;

const Navbar = () => {
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility

  const toggleNavbar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  const [isClick, setisClick] = useState(false);

  const toggleNavbaRes = () => {
    setisClick(!isClick);
  };

  return (
    <>
      {/* Navbar */}
      <nav
        className={`fixed w-full z-10 top-0 font-inter transition-all duration-300 ${
          isScrolled || isClick ? "bg-white shadow-xl" : "bg-inherit"
        }`}
      >
        <div className="max-w-full mx-auto px-3 lg:px-6">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a
                  href="/"
                  className="text-primary flex items-center font-bold text-lg lg:text-[35px]"
                >
                  SITAPIC
                </a>
              </div>
            </div>
            {location.pathname !== "/" &&
            location.pathname !== "/profesional" ? (
              <>
                <div className="lg:hidden flex items-center">
                  <button
                    className="inline-flex items-center justify-center p-2 rounded-md text-black md:text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={toggleNavbaRes}
                  >
                    {isClick ? (
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 6h16M4 12h16m-7 6h7"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <div className="hidden lg:flex items-center space-x-6 text-sm font-inter">
                  <a
                    href={"/"}
                    className="text-primary font-bold cursor-pointer py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-primary after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                  >
                    Event
                  </a>
                  <a
                    href={"/jurnal"}
                    className="text-primary font-bold cursor-pointer py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-primary after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                  >
                    Clinical Evidence
                  </a>
                  <a
                    href={"/pengaduan"}
                    className="text-primary font-bold cursor-pointer py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-primary after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
                  >
                    Layanan Pengaduan
                  </a>
                </div>
              </>
            ) : null}
          </div>
        </div>

        {isClick && (
          <div className="bg-white">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-sm font-inter">
              <a
                href={"/"}
                className="text-primary font-bold py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-primary after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Event
              </a>
              <a
                href={"/jurnal"}
                className="text-primary font-bold cursor-pointer py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-primary after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Clinical Evidence
              </a>
              <a
                href={"/pengaduan"}
                className="text-primary font-bold cursor-pointer py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-primary after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Layanan Pengadauan
              </a>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
