import React, { useEffect, useState } from "react";

function ModalEvent({ onCloseModal, idModal, nameModal }) {
  const onCloseModalProperty = () => {
    onCloseModal();
  };

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>
      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed inset-0 z-40 flex justify-center items-center"
      >
        <div className="relative p-1  mx-[3%] my-[2%] max-w-4xl">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow ">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-[#1E6C93] dark:text-white">
                {nameModal}
              </h3>
            </div>
            {/* Modal body */}
            <div className="p-2 md:p-5">
              <img
                class="rounded-lg lg:ml-auto w-full h-full lg:max-w-full lg:h-[600px]  "
                src={`https://be.sitapic.com/landing/public/api/event/file/${idModal}`}
                alt=""
              />
            </div>
            <div className="flex justify-center">
              <button
                onClick={onCloseModalProperty}
                className="bg-[#1E6C93] text-white rounded-md  hover:text-white font-inter font-bold p-2 text-[10px] md:text-[10px] md:p-4 lg:px-12 lg:py-3 lg:text-[12px] mb-4 "
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalEvent;
