import APIClient from "../APIClient";

const token = localStorage.getItem("token");

export const AuthLogin = async (user, password) => {
  const param = {
    username: user,
    password: password,
  };
  const result = await APIClient.post("/login", param).then((res) => res);
  return result;
};

export const getListComplaint = async () => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.get(`/api/complaint`, config).then(
    (res) => res
  );
  return result;
};

export const getListComplaintArsip = async (TanggalMulai, TanggalAkhir) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  // const result = await APIClient.get(`/api/complaint`, config).then(
  //   (res) => res
  // );
  // return result;
  const formData = new FormData();
  formData.append("startDate", TanggalMulai);
  formData.append("endDate", TanggalAkhir);
  const result = await APIClient.post(
    "/api/complaint/history",
    formData,
    config
  ).then((res) => res);
  return result;
};

export const updateStatusComplaint = async (idComplaint) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.put(
    `/api/complaint/${idComplaint}`,
    config
  ).then((res) => res);
  return result;
};

export const getListJurnal = async () => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.get(`/api/jurnal`, config).then((res) => res);
  return result;
};

export const PostJurnal = async (idJurnal, jurnal, file, desk) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  if (idJurnal === null) {
    const formData = new FormData();
    formData.append("jurnalName", jurnal);
    formData.append("description", desk);
    formData.append("jurnalFile", file);
    const result = await APIClient.post("/api/jurnal", formData, config).then(
      (res) => res
    );
    return result;
  } else {
    const formData = new FormData();
    formData.append("jurnalName", jurnal);
    formData.append("description", desk);
    formData.append("jurnalFile", file);

    const result = await APIClient.post(
      `/api/jurnal/${idJurnal}`,
      formData,
      config
    ).then((res) => res);
    return result;
  }
};

export const GetDetailJurnal = async (idJurnal) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.get(`/api/jurnal/${idJurnal}`, config).then(
    (res) => res
  );
  return result;
};

export const updateStatusJurnal = async (idJurnal) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.put(`/api/jurnal/${idJurnal}`, config).then(
    (res) => res
  );
  return result;
};

export const getListEvent = async () => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.get(`/api/event`, config).then((res) => res);
  return result;
};

export const PostEvent = async (
  idEvent,
  event,
  file,
  TanggalMulai,
  TanggalAkhir
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  if (idEvent === null) {
    const formData = new FormData();
    formData.append("eventName", event);
    formData.append("startDate", TanggalMulai);
    formData.append("endDate", TanggalAkhir);
    formData.append("eventFile", file);
    const result = await APIClient.post("/api/event", formData, config).then(
      (res) => res
    );
    return result;
  } else {
    const formData = new FormData();
    formData.append("eventName", event);
    formData.append("startDate", TanggalMulai);
    formData.append("endDate", TanggalAkhir);
    formData.append("eventFile", file);

    const result = await APIClient.post(
      `/api/event/${idEvent}`,
      formData,
      config
    ).then((res) => res);
    return result;
  }
};

export const GetDetailEvent = async (idEvent) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.get(`/api/event/${idEvent}`, config).then(
    (res) => res
  );
  return result;
};

export const getListContent = async () => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.get(`/api/content/0`, config).then(
    (res) => res
  );
  return result;
};

export const PostContent = async (idContent, file) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  if (idContent === null) {
    const formData = new FormData();
    formData.append("contentFile", file);
    const result = await APIClient.post("/api/content", formData, config).then(
      (res) => res
    );
    return result;
  } else {
    const formData = new FormData();
    formData.append("contentFile", file);

    const result = await APIClient.post(
      `/api/content/${idContent}`,
      formData,
      config
    ).then((res) => res);
    return result;
  }
};

export const GetDetailContent = async (idContent) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const result = await APIClient.get(`/api/content/${idContent}`, config).then(
    (res) => res
  );
  return result;
};
