import React, { useState, useEffect } from "react";
import Full from "../../load/Laoding";
import {
  getListJurnal,
  updateStatusJurnal,
} from "../../service/admin/AdminService";
import ModalAddJurnal from "../admin/modal/ModalAddJurnal";
import Swal from "sweetalert2";

const MonitorJurnal = () => {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [dataJurnal, setDataJurnal] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idJurnal, setIdJurnal] = useState("");

  useEffect(() => {
    GetDataJurnal();
  }, []);

  const GetDataJurnal = () => {
    showLoader();
    getListJurnal()
      .then((res) => {
        setDataJurnal(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          window.location = "/admin-sitapic";
          localStorage.removeItem("token");
          hideLoader();
        } else {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        }
      });
  };

  const JurnalActive = (idJurnal) => {
    showLoader();
    updateStatusJurnal(idJurnal)
      .then((res) => {
        Swal.fire({
          title: res.data.message_1,
          text: res.data.message_2,
          showConfirmButton: true,
          icon: "info",
          allowOutsideClick: false, // Prevent closing by clicking outside
          allowEscapeKey: false, // Prevent closing by pressing the Escape key
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          window.location = "/admin-sitapic";
          localStorage.removeItem("token");
          hideLoader();
        } else {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        }
      });
  };

  const openModalAddJurnal = () => {
    setModalIsOpen(true);
    setIdJurnal(null);
  };

  const openModalAEditJurnal = (id) => {
    setModalIsOpen(true);
    setIdJurnal(id);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (dataJurnal.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(function () {
      $("#example2")
        .DataTable({
          responsive: false,
          info: false,
          lengthChange: false,
        })
        .columns.adjust()
        .responsive.recalc();
    });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [dataJurnal]);

  return (
    <>
      <div className="mt-12">
        <h5 className="lg:text-[30px] text-md font-inter font-bold">
          Monitoring Clinical Evidence
        </h5>
        <div className="grid grid-cols-1 gap-2 lg:pb-4 ">
          <div className="col-span-1 flex justify-end items-center">
            {/* Button aligned to the right in the second column */}
            <button
              onClick={openModalAddJurnal}
              className="bg-[#0368DE] hover:opacity-80 text-white font-bold py-2 px-4 rounded text-sm lg:text-md"
            >
              Tambah Clinical Evidence
            </button>
          </div>
        </div>

        <div
          id="recipients"
          class="p-8 mt-6 lg:mt-0 rounded-xl shadow-xl bg-white border border-slate-100"
        >
          <div className="overflow-x-auto relative">
            <table
              id="example2"
              className="stripe hover text-sm lg:text-md"
              style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}
            >
              <thead className="bg-primary text-white">
                <tr>
                  <th data-priority={1} className="sticky left-0 bg-primary">
                    NO.
                  </th>
                  <th data-priority={2} className="sticky left-12 bg-primary">
                    JUDUL CLINICAL EVIDENCE
                  </th>
                  <th data-priority={3}>FILE CLINICAL EVIDENCE</th>
                  <th data-priority={4}>DESKRIPSI SINGKAT</th>
                  <th data-priority={5}>TANGGAL TERBIT</th>
                  <th data-priority={6} width={"15%"}>
                    AKSI
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataJurnal.length === 0 ? (
                  <tr className="text-center font-inter ">
                    <td colSpan={"5"} className="py-6 text-lg">
                      {" "}
                      Data Belum Ada
                    </td>
                  </tr>
                ) : (
                  <>
                    {dataJurnal?.map((list, index) => {
                      return (
                        <tr className="text-center" key={index}>
                          <td className="sticky left-0 bg-white">
                            {index + 1}.
                          </td>
                          <td className="sticky left-12 bg-white">
                            {list.jurnal_name}
                          </td>
                          <td>{list.file_name}</td>
                          <td>{list.description}</td>
                          <td>{list.created_at}</td>
                          <td>
                            <div className="grid grid-cols-12 md:grid-cols-6 lg:grid-cols-6 gap-1">
                              <div className="lg:col-span-3 md:col-span-3 col-span-full">
                                {" "}
                                <button
                                  onClick={() => openModalAEditJurnal(list.id)}
                                  className="bg-[#A14A76] hover:opacity-70 text-white text-[12px] lg:text-[15px] font-light py-1 px-1  md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-lg "
                                >
                                  Perbarui
                                </button>
                              </div>
                              <div className="lg:col-span-3 md:col-span-3 col-span-full ">
                                {" "}
                                <button
                                  onClick={() => JurnalActive(list.id)}
                                  className="bg-[#4AA16D] hover:opacity-70 text-white text-[12px] lg:text-[15px] font-light py-1 px-1  md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-lg "
                                >
                                  {list.status === "0" ? (
                                    <span>Tidak Aktif</span>
                                  ) : (
                                    <span>Aktif</span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {modalIsOpen ? (
        <ModalAddJurnal onCloseModal={closeModal} idJurnal={idJurnal} />
      ) : null}
    </>
  );
};

export default MonitorJurnal;
