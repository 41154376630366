import React from "react";
import prof from "../assets/img/prof.png";
import { Link } from "react-router-dom";

const Profesional = () => {
  return (
    <>
      {/* persyaratan */}
      <section className="lg:pt-[100px] pt-[40px]  mx-8">
        <div className="grid lg:grid-cols-12 gap-4 sm:grid-cols-1 lg:my-12 lg:mx-20 ">
          {/* Content for the first column */}
          <div className="lg:col-span-4 sm:col-span-full mx-auto lg:mx-0 mt-8 lg:mt-0 flex justify-start ">
            <img
              src={prof}
              alt="syarat logo"
              className="w-full h-full object-cover"
            />
          </div>

          {/* Content for the second column */}
          <div className="lg:col-span-8 sm:col-span-full mx-4 ">
            <h5 className="text-primary text-center text-xl lg:text-[40px] mt-2 font-reguler font-inter  leading-relaxed lg:leading-normal lg:py-8 lg:px-12 ">
              Dengan ini saya menyatakan bahwa saya tenaga profesional
              kesehatan, penyalahgunaan informasi yang ada di website ini diluar
              tanggung jawab kami
            </h5>

            <h5 className="text-[12px] lg:text-[18px] text-center  font-reguler font-inter lg:py-3 py-4">
              Jika anda profesional tenaga kesehatan silahkan klik tautan di
              bawah
            </h5>
            <div className="grid lg:grid-cols-1 lg:mx-16 lg:mt-6">
              {/* Content for the first column - Button */}
              <Link to="/nakes">
                <div className="lg:col-span-full col-span-full mx-0 mt-8 lg:mt-0 flex justify-center">
                  <button className="bg-[#1E6C93] w-full text-white rounded-md uppercase hover:bg-[#1AA179] font-inter font-bold p-2 text-[10px] md:text-[14px] md:p-4 lg:px-24 lg:py-3 lg:text-[18px]">
                    KLIK DI SINI
                  </button>
                </div>
              </Link>
              <Link to="/">
                <div className="lg:col-span-full col-span-full mx-0 mt-3 lg:mt-3 flex justify-center">
                  <button className="bg-white w-full text-primary border border-[#1E6C93] rounded-md uppercase hover:bg-[#1AA179] hover:text-white hover:border-[#1AA179] font-inter font-bold p-2 text-[10px] md:text-[14px] md:p-4 lg:px-24 lg:py-3 lg:text-[18px]">
                    SAYA BUKAN PROFESIONAL TENAGA KESEHATAN
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Profesional;
