import React, { useState, useEffect, useRef } from "react";
import Full from "../load/Laoding";
import Swal from "sweetalert2";
import { getDataChannel, sendComplaint } from "../service/page/PageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
const stopwatch = <FontAwesomeIcon icon={faDownload} />;

const JurnalPengaduan = () => {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [dataChannel, setDataChannel] = useState([]);
  const [email, setEmail] = useState("");
  const [namaDokter, setNamaDokter] = useState("");
  const [tempatPraktek, setTempatPraktek] = useState("");
  const [namaTempat, setNamaTempat] = useState("");
  const [alamatPraktek, setAlamatPraktek] = useState("");
  const [deskMasalah, setDeskMasalah] = useState("");
  const [file, setFile] = useState("");
  const [error, setError] = useState(false);
  // const [errorMsg, setErrorMsg] = useState(false);

  const fileInputRef = useRef(null);

  const handleSelectChange = (e) => {
    setTempatPraktek(e.target.value);
  };

  useEffect(() => {
    GetChannel();
  }, []);

  const GetChannel = () => {
    showLoader();
    getDataChannel()
      .then((res) => {
        setDataChannel(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          showConfirmButton: false,
          icon: "warning",
        });
        hideLoader();
      });
  };

  const handleSubmit = async (event) => {
    showLoader();
    event.preventDefault();
    if (
      email.length == 0 ||
      namaDokter.length == 0 ||
      tempatPraktek.length == 0 ||
      namaTempat.length == 0 ||
      alamatPraktek.length == 0 ||
      deskMasalah.length == 0 ||
      file.length == 0
    ) {
      setError(true);
      hideLoader();
      return;
    }
    // const MAX_FILE_SIZE = 20000; // 20MB
    // const fileSizeKiloBytes = file.size / 100;

    // if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    //   setErrorMsg("File Size Maksimal 20MB!!");
    //   hideLoader();
    //   return;
    // }

    // Create a FormData object
    const formData = new FormData();

    formData.append("email", email);
    formData.append("personName", namaDokter);
    formData.append("locationName", namaTempat);
    formData.append("addressName", alamatPraktek);
    formData.append("description", deskMasalah);
    formData.append("channelTypeId", tempatPraktek);
    formData.append("complaintFile", file);

    sendComplaint(formData)
      .then((res) => {
        Swal.fire({
          title: res.data.message,
          showConfirmButton: false,
          icon: "success",
        });
        hideLoader();
        setEmail("");
        setNamaDokter("");
        setTempatPraktek("");
        setNamaTempat("");
        setAlamatPraktek("");
        setDeskMasalah("");
        setFile(null);

        // Resetting the file input field
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        hideLoader();
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          showConfirmButton: false,
          icon: "warning",
        });
        hideLoader();
      });
  };

  const changeHandlerpdf = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/jpg",
      ];

      const fileName = selectedFile.name;
      const fileNameWithoutExtension = fileName.substring(
        0,
        fileName.lastIndexOf(".")
      );

      // Regular expression to check for invalid characters in the file name (excluding the extension)
      const invalidCharactersRegex = /[.,_\-=\+]/;

      if (
        !invalidCharactersRegex.test(fileNameWithoutExtension) &&
        validTypes.includes(fileType)
      ) {
        setFile(selectedFile);
      } else {
        // Clear the input value
        event.target.value = "";
        Swal.fire({
          title:
            "Hanya file dengan format PDF, PNG, JPG, atau JPEG yang diperbolehkan dan nama file tidak mengandung simbol ( . , _ - = + ) !!",
          showConfirmButton: false,
          icon: "warning",
        });
      }
    }
  };

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* hero */}
      <div className="bg-custom-pattern2 bg-cover bg-center h-[36vh] w-full md:h-[50vh] lg:h-[100vh] flex items-end justify-start"></div>

      {/* penjelasan sitapic */}
      <section className="lg:pt-[3px] mb-8 mx-3">
        <div className="lg:my-12 lg:mx-28">
          <h1 className=" text-primary text-md lg:text-[24px] font-semibold tracking-wide mt-6 mb-2 lg:mb-[24px] font-inter">
            LAYANAN PENGADUAN
          </h1>
          <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
          <div className="grid lg:grid-cols-12 gap-4 ">
            {/* Content for the first column - Text (8 columns) */}
            <div className="lg:col-span-8 col-span-full">
              <h5 className="text-[14px] lg:text-[16px] lg:pb-3 text-black font-regular font-inter">
                1. Silakan unduh template pengaduan terlebih dahulu.
              </h5>
              <h5 className="text-[14px] lg:text-[16px] lg:pb-3 text-black font-regular font-inter">
                2. Isi form di bawah terkait penggunaan Produk Sitapic.
              </h5>
            </div>
            {/* Content for the second column - Button (4 columns) */}
            <div className="lg:col-span-4 col-span-full mx-auto lg:m-0 flex lg:justify-end justify-center ">
              <a
                href={`https://be.sitapic.com/landing/public/api/content/ktd`}
                target="_blank"
                download="form_pengaduan.pdf"
              >
                <button className="bg-[#A14A76] text-white rounded-md hover:bg-[#1AA179] hover:text-white font-inter font-regular p-2 text-[11px] md:text-[12px] md:p-4 lg:px-6 lg:py-2 lg:text-[14px] mb-4 lg:mb-12">
                  Unduh Template
                </button>
              </a>
            </div>
          </div>

          <div className="lg:mt-6">
            <div className=" bg-[#1E6C93] rounded-t-xl">
              <h5 className="text-center uppercase lg:text-[16px] text-[12px] font-inter font-semibold text-white py-4 ">
                Laporan Pengaduan dan Keluhan Penggunaan Sitapic
              </h5>
            </div>
            <div className=" border border-slate-600 rounded-b-xl">
              <div className="p-4 lg:py-6 lg:px-12 md:p-5">
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <div className="flex flex-wrap">
                    <label
                      htmlFor="email"
                      className="w-[100%] lg:w-[20%] block mb-2 pt-2  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      E-mail
                    </label>
                    <div className="relative w-[100%] lg:w-[80%]">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        placeholder="Masukan E-mail Anda"
                        maxLength={100}
                        className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow block w-[100%] p-2.5 "
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div className="text-xs text-red-700 mb-3">
                        {error && email.length <= 0 ? (
                          <label>Email Tidak Boleh Kosong!</label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <label
                      htmlFor="nama"
                      className="w-[100%] lg:w-[20%] block mb-2 pt-2  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Nama Dokter
                    </label>
                    <div className="relative w-[100%] lg:w-[80%]">
                      <input
                        type="text"
                        name="nama"
                        id="nama"
                        value={namaDokter}
                        placeholder="Masukan Nama Dokter"
                        maxLength={100}
                        className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow block w-[100%] p-2.5 "
                        onChange={(e) => {
                          setNamaDokter(e.target.value);
                        }}
                      />
                      <div className="text-xs text-red-700 mb-3">
                        {error && namaDokter.length <= 0 ? (
                          <label>Nama Dokter Tidak Boleh Kosong!</label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <label
                      htmlFor="tempat"
                      className="w-[100%] lg:w-[20%] block mb-2 pt-2  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Tempat Praktek
                    </label>
                    <div className="relative w-[100%] lg:w-[80%]">
                      <select
                        className="mb-2 block max-w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 w-[100%]"
                        value={tempatPraktek}
                        onChange={handleSelectChange}
                      >
                        <option value="" disabled selected>
                          Silahkan Pilih
                        </option>
                        {dataChannel?.map((list, index) => {
                          return (
                            <option key={index} value={list.id}>
                              {list.channel_type_name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="text-xs text-red-700 mb-3">
                        {error && tempatPraktek.length <= 0 ? (
                          <label>Tempat Praktek Belum di Pilih!</label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {tempatPraktek && (
                      <>
                        <div className="w-[100%] lg:w-[20%] block"></div>
                        <div className="relative w-[100%] lg:w-[80%]">
                          <div className="lg:flex items-center mb-2">
                            <label
                              htmlFor="nama"
                              className="w-[100%] lg:w-[20%] text-sm mb-2 block  font-medium text-gray-900 dark:text-white"
                            >
                              Nama Tempat Praktek
                            </label>
                            <div className="relative w-[100%] lg:w-[80%]">
                              <input
                                type="text"
                                name="nama"
                                id="nama"
                                value={namaTempat}
                                placeholder="Masukan Nama Tempat Praktek"
                                maxLength={100}
                                className="bg-gray-50 border mb-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow block w-full p-2.5"
                                onChange={(e) => {
                                  setNamaTempat(e.target.value);
                                }}
                              />
                              <div className="text-xs text-red-700 mb-3">
                                {error && namaTempat.length <= 0 ? (
                                  <label>
                                    Nama Tempat Praktek Tidak Boleh Kosong!
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex items-center mb-2">
                            <label
                              htmlFor="alamat"
                              className="w-[100%] lg:w-[20%]  block mb-2 pt-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Alamat Tempat Praktek
                            </label>
                            <div className="relative w-[100%] lg:w-[80%]">
                              <textarea
                                type="text"
                                name="alamat"
                                id="alamat"
                                value={alamatPraktek}
                                maxLength={255}
                                placeholder="Masukan Alamat Tempat Praktek"
                                className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow block w-[100%] p-2.5 "
                                onChange={(e) => {
                                  setAlamatPraktek(e.target.value);
                                }}
                              />
                              <div className="text-xs text-red-700 mb-3">
                                {error && alamatPraktek.length <= 0 ? (
                                  <label>
                                    {" "}
                                    Alamat Tempat Praktek Tidak Boleh Kosong!
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <label
                      htmlFor="masalah"
                      className="w-[100%] lg:w-[20%] block mb-2 pt-2  text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Deskripsi Masalah
                      <p className="text-[10px] text-slate-400 font-inter italic">
                        *Maksimal 300 Karakter
                      </p>
                    </label>
                    <div className="relative w-[100%] lg:w-[80%]">
                      <textarea
                        type="textarea"
                        name="masalah"
                        id="masalah"
                        placeholder="Masukan Deskripsi Masalah"
                        value={deskMasalah}
                        maxLength={300}
                        className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow block w-[100%] p-2.5 h-[150px]"
                        onChange={(e) => {
                          setDeskMasalah(e.target.value);
                        }}
                      />
                      <div className="text-xs text-red-700 mb-3">
                        {error && deskMasalah.length <= 0 ? (
                          <label>Deskripsi Masalah Tidak Boleh Kosong!</label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <label
                      htmlFor="kompetensi"
                      className="w-[100%] lg:w-[20%] block mb-2 pt-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Unggah Template Form (yang sudah diisi)
                    </label>
                    <div className="relative w-[100%] lg:w-[80%] h-[90px]">
                      <div className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow flex items-center justify-between p-2.5 cursor-pointer">
                        <span className="truncate">
                          {file ? file.name : "Choose a file..."}
                        </span>
                        <input
                          type="file"
                          name="kompetensi"
                          id="kompetensi"
                          ref={fileInputRef}
                          className="opacity-0 absolute inset-0 h-full w-full cursor-pointer"
                          onChange={changeHandlerpdf}
                          accept=".pdf,.png,.jpg,.jpeg"
                        />
                        <button className="ml-2 text-honeyYellow">
                          Browse
                        </button>
                      </div>

                      <div className="text-xs text-red-700 mb-3">
                        {/* <label>{errorMsg}</label> */}
                        {error && file.length <= 0 ? (
                          <label>File Tidak Boleh Kosong!</label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center text-white">
                    <button
                      type="submit"
                      className=" bg-[#1E6C93] rounded-md px-5 py-2  "
                    >
                      Kirim Laporan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loader}
    </>
  );
};

export default JurnalPengaduan;
