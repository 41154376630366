import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { PostEvent, GetDetailEvent } from "../../../service/admin/AdminService";
import Full from "../../../load/Laoding";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ModalAddEvent({ onCloseModal, idEvent }) {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [event, setEvent] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setNameFile] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dateStart = new Date(startDate);
  const dateEnd = new Date(endDate);

  const formatDateStart = (dateStart) => {
    const year = dateStart.getFullYear();
    const month = (dateStart.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11, add 1 for 1-12
    const day = dateStart.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const formatDateEnd = (dateEnd) => {
    const year = dateEnd.getFullYear();
    const month = (dateEnd.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11, add 1 for 1-12
    const day = dateEnd.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const TanggalMulai = formatDateStart(dateStart);
  const TanggalAkhir = formatDateEnd(dateEnd);

  const changeHandlerpdf = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];

      const fileName = selectedFile.name;
      const fileNameWithoutExtension = fileName.substring(
        0,
        fileName.lastIndexOf(".")
      );

      // Regular expression to check for invalid characters in the file name (excluding the extension)
      const invalidCharactersRegex = /[.,_\-=\+]/;

      if (
        !invalidCharactersRegex.test(fileNameWithoutExtension) &&
        validTypes.includes(fileType)
      ) {
        setFile(selectedFile);
      } else {
        // Clear the input value
        event.target.value = "";
        Swal.fire({
          title:
            "Hanya file dengan format PNG, JPG, atau JPEG yang diperbolehkan dan nama file tidak mengandung simbol ( . , _ - = + ) !!",
          showConfirmButton: false,
          icon: "warning",
        });
      }
    }
  };
  console.log(startDate);
  const sendModule = async () => {
    if (
      event.length == 0 ||
      file.length == 0 ||
      startDate.length == 0 ||
      endDate.length == 0
    ) {
      setError(true);
    }
    const MAX_FILE_SIZE = 210000; // 20MB
    const fileSizeKiloBytes = file.size / 100;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File Size Maksimal 20MB!!");
      return;
    }

    showLoader();

    PostEvent(idEvent, event, file, TanggalMulai, TanggalAkhir)
      .then((res) => {
        if (res.data.success === true) {
          onCloseModalProperty();
          Swal.fire({
            title: "Event berhasil ditambahkan!!",
            showConfirmButton: false,
            icon: "success",
          });
          hideLoader();
          window.location.reload();
        } else {
          Swal.fire({
            title: res.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          window.location = "/admin-sitapic";
          hideLoader();
        } else {
          Swal.fire({
            title: `${err.response.data.message}`,
            showConfirmButton: false,
            icon: "warning",
          });
        }
        hideLoader();
      });
  };

  useEffect(() => {
    if (idEvent) {
      getDetailEvent(idEvent);
    } else {
      setEvent("");
      setStartDate("");
      setEndDate("");
    }
  }, [idEvent]);

  const getDetailEvent = () => {
    showLoader();
    GetDetailEvent(idEvent)
      .then((res) => {
        if (res.data.success === true) {
          setEvent(res.data.data.event_name);
          setNameFile(res.data.data.file_name);
          setStartDate(res.data.data.start_date);
          setEndDate(res.data.data.end_date);
        } else {
          Swal.fire({
            title: res.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
        }
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
      });
  };

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30"></div>
      <div
        tabIndex={-1}
        aria-hidden="true"
        className="overflow-y-auto overflow-x-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative p-2 w-full mx-auto my-[10%] max-w-xl max-h-full">
          {/* Modal content */}
          <div className="relative bg-white rounded-xl shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              {idEvent ? (
                <h3 className="text-l font-semibold text-gray-900 dark:text-white text-center">
                  Perbarui Event
                </h3>
              ) : (
                <h3 className="text-l font-semibold text-gray-900 dark:text-white text-center">
                  Tambah Event
                </h3>
              )}

              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg w-2 h-2 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5">
              <form className="space-y-4" onSubmit={handleSubmit(sendModule)}>
                <div className="flex flex-wrap">
                  <label
                    htmlFor="jurnal"
                    className="w-[25%] block mb-2 pt-2  text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Judul Event
                    <p className="text-[10px] text-slate-400 font-inter italic">
                      *Maksimal 100 Karakter
                    </p>
                  </label>
                  <div className="relative w-[75%]">
                    <input
                      type="text"
                      name="event"
                      id="event"
                      placeholder="Masukan Judul Event"
                      value={event}
                      maxLength={100}
                      className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow block w-[100%] p-2.5 "
                      onChange={(e) => {
                        setEvent(e.target.value);
                      }}
                    />
                    <div className="text-xs text-red-700 mb-3">
                      {error && event.length <= 0 ? (
                        <label>Judul Event Tidak Boleh Kosong!!</label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <label
                    htmlFor="jurnalFile"
                    className="w-[25%] block mb-2 pt-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Unggah Poster
                  </label>
                  <div className="relative w-[75%] ">
                    <div className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow flex items-center justify-between p-2.5 cursor-pointer">
                      <span className="truncate">
                        {file ? file.name : "Choose a file..."}
                      </span>
                      <input
                        type="file"
                        name="EventFile"
                        id="EventFile"
                        className="opacity-0 absolute inset-0 h-full w-full cursor-pointer"
                        onChange={changeHandlerpdf}
                        accept=".jpg,.jpeg,.png"
                      />
                      <button className="ml-2 text-honeyYellow">Browse</button>
                    </div>

                    {idEvent ? (
                      <div className="">
                        <label className=" text-xs text-honeyYellow">
                          {fileName}
                        </label>
                      </div>
                    ) : null}
                    <div className="text-xs text-red-700 mb-3">
                      <label>{errorMsg}</label>
                      {error && file.length <= 0 ? (
                        <label>File Tidak Boleh Kosong!</label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <label
                    htmlFor="jurnalFile"
                    className="w-[25%] block mb-2 pt-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Periode Tayang
                  </label>
                  <div className="relative w-[75%]   ">
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                      <div>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          popperPlacement="bottom-end"
                          selectsStart
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Start Date"
                          className="p-1 border rounded-md w-full sm:w-auto"
                          showYearDropdown
                          showMonthDropdown
                          onKeyDown={(e) => e.preventDefault()}
                        />

                        <div className="text-xs text-red-700 mb-3 mt-2">
                          {error && startDate.length <= 0 ? (
                            <label>Priode Tayang Tidak Boleh Kosong!!</label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          selectsEnd
                          minDate={startDate}
                          popperPlacement="bottom-end"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="End Date"
                          disabled={!startDate}
                          className={`p-1 border rounded-md w-full sm:w-auto mb-3 lg:mb-0 ${
                            !startDate ? "opacity-45" : ""
                          }`}
                          showYearDropdown
                          showMonthDropdown
                          onKeyDown={(e) => e.preventDefault()}
                        />
                        <div className="text-xs text-red-700 mb-3 mt-2">
                          {error && endDate.length <= 0 ? (
                            <label>Priode Tayang Tidak Boleh Kosong!!</label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {idEvent ? (
                  <div className="flex justify-center text-white ">
                    <button
                      type="submit"
                      className={`w-1/2 bg-primary rounded-lg px-5 py-2 ${
                        !startDate || !endDate ? "hidden" : ""
                      }`}
                    >
                      Update
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center text-white">
                    <button
                      type="submit"
                      className={`w-1/2 bg-primary rounded-lg px-5 py-2 ${
                        !startDate || !endDate ? "hidden" : ""
                      }`}
                    >
                      Tambahkan
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalAddEvent;
