import { useLocation, Link, Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
const home = <FontAwesomeIcon icon={faHome} />;
const log = <FontAwesomeIcon icon={faRightFromBracket} />;

const Navbar = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility

  const toggleNavbar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  const [isClick, setisClick] = useState(false);

  const toggleNavbaRes = () => {
    setisClick(!isClick);
  };

  const logout = async () => {
    Swal.fire({
      title: "Apakah Anda Yakin Keluar?",
      showDenyButton: true,
      icon: "info",
      confirmButtonText: "Ya, Keluar",
      denyButtonText: `Batal`,
      customClass: {
        confirmButton: "btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location = "/admin-sitapic";
        localStorage.removeItem("token");
      }
    });
  };

  return (
    <>
      {/* Navbar */}
      <nav
        className={`fixed w-full z-10 top-0 font-inter transition-all duration-300 bg-white shadow-md`}
      >
        <div
          // className=""
          className={`transition-all duration-300 max-w-full mx-auto px-3 lg:px-2${
            isSidebarOpen ? "ml-64" : "ml-0"
          }`}
        >
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="lg:block  flex items-center">
                <div className="hidden lg:block">
                  <button
                    className="inline-flex  items-center justify-center p-2 rounded-md text-black md:text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={toggleNavbar}
                  >
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 12h16m-7 6h7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {!isSidebarOpen ? (
                <div className="flex-shrink-0">
                  <a
                    href="#"
                    className="text-black flex items-center font-semibold text-lg lg:text-[18px]"
                  >
                    ADMIN SITAPIC
                  </a>
                </div>
              ) : null}
            </div>
            <div className="lg:hidden flex items-center">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-black md:text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={toggleNavbaRes}
              >
                {isClick ? (
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-sm font-inter hidden lg:block">
              <a
                onClick={logout}
                className="cursor-pointer py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                Logout<span className="lg:mx-3">{log}</span>
              </a>
            </div>
          </div>
        </div>
        {isClick && (
          <div className="">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-sm font-inter">
              <a
                href="/dashboard-sitapic"
                // onClick={((e) => handleScroll(e, "perusahaan"), toggleNavbar)}
                className="py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                <span className="lg:mx-3 mr-1">{home}</span>Beranda
              </a>
              <a
                onClick={logout}
                className=" cursor-pointer py-2 mx-auto relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                <span className="lg:mx-3 mr-1 ">{log}</span>Logout
              </a>
            </div>
          </div>
        )}
      </nav>

      {/* Sidebar */}
      <div
        className={`fixed left-0 top-0 h-full bg-white shadow-md z-20 transition-transform duration-300 ease-in-out ${
          isSidebarOpen
            ? "transform translate-x-0"
            : "transform -translate-x-full"
        }`}
      >
        <div className="lg:pr-8 lg:pl-4 lg:py-4">
          <h2 className="text-lg font-bold">
            <div className="flex items-center">
              <div className="lg:block flex items-center">
                <button
                  className="inline-flex items-center justify-center p-2 rounded-md text-black md:text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={toggleNavbar}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-shrink-0">
                <a
                  href="#"
                  className="text-black flex items-center font-semibold text-lg lg:text-[18px]"
                >
                  ADMIN SITAPIC
                </a>
              </div>
            </div>
          </h2>
          <ul className="mt-4 ml-7">
            <li className="py-2">
              <Link
                to="/dashboard-sitapic"
                className="text-gray-800 hover:text-black lg:text-[18px] font-reguler relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#302BB2] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
              >
                <span className="lg:mx-3">{home}</span>Beranda
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Main Content Area */}
      <div
        className={`pt-16 transition-all duration-300 ${
          isSidebarOpen ? "lg:ml-60 lg:mr-3 lg:mt-4" : "lg:mx-14 lg:mt-4"
        }`}
      >
        {/* Add your main content here */}
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Navbar;
