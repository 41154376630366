import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { getListComplaintArsip } from "../../../service/admin/AdminService";
import Full from "../../../load/Laoding";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ModalArsip({ onCloseModal, idEvent }) {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dateStart = new Date(startDate);
  const dateEnd = new Date(endDate);

  const formatDateStart = (dateStart) => {
    const year = dateStart.getFullYear();
    const month = (dateStart.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11, add 1 for 1-12
    const day = dateStart.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const formatDateEnd = (dateEnd) => {
    const year = dateEnd.getFullYear();
    const month = (dateEnd.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11, add 1 for 1-12
    const day = dateEnd.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const TanggalMulai = formatDateStart(dateStart);
  const TanggalAkhir = formatDateEnd(dateEnd);

  const [dataComplaint, setDataComplaint] = useState([]);

  const GetDataComplaint = () => {
    showLoader();
    if (startDate.length == 0 || endDate.length == 0) {
      setError(true);
    }
    getListComplaintArsip(TanggalMulai, TanggalAkhir)
      .then((res) => {
        if (res.data.success == true) {
          setDataComplaint(res.data.data);
          hideLoader();
        } else {
          Swal.fire({
            title: res.data.data,
            showConfirmButton: false,
            icon: "warning",
          });
          setDataComplaint([]);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          window.location = "/admin-sitapic";
          localStorage.removeItem("token");
          hideLoader();
        } else {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        }
      });
  };

  const handleDownload = async (id, nomor, ext) => {
    try {
      // Assuming you have the token stored in local storage or in a state
      const token = localStorage.getItem("token");

      // Set up the request headers with the authorization token
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Make the API call to download the file
      const response = await axios.get(
        `https://be.sitapic.com/admin/public/api/complaint/file/${id}`,
        {
          headers: headers,
          responseType: "blob", // Important for downloading files
        }
      );

      // Create a URL for the blob and set up the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Pengaduan-${nomor}.${ext}`); // You can set the file name dynamically if needed
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up the DOM by removing the temporary link
    } catch (error) {
      console.error("Error downloading the file:", error);
      // Optionally, display an error message to the user
    }
  };

  useEffect(() => {
    if (dataComplaint.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(function () {
      $("#example11")
        .DataTable({
          responsive: false,
          info: false,
          lengthChange: false,
        })
        .columns.adjust()
        .responsive.recalc();
    });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [dataComplaint]);

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30"></div>
      <div
        tabIndex={-1}
        aria-hidden="true"
        className="overflow-y-auto overflow-x-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative px-12 w-full mx-auto my-[5%]  max-h-full">
          {/* Modal content */}
          <div className="relative bg-white rounded-xl shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-l font-semibold text-gray-900 dark:text-white text-center">
                Arsip Pengaduan
              </h3>

              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg w-2 h-2 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5">
              <form
                className="space-y-4"
                onSubmit={handleSubmit(GetDataComplaint)}
              >
                <div className="flex flex-wrap">
                  <label
                    htmlFor="jurnalFile"
                    className="lg:w-[5%] w-[100%] block  mt-1 font-medium text-gray-900 dark:text-white"
                  >
                    Tanggal
                  </label>
                  <div className="relative lg:w-[40%]  w-[100%] pt-2 lg:pt-0">
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        startDate={startDate}
                        endDate={endDate}
                        popperPlacement="bottom-end"
                        selectsStart
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Start Date"
                        className="p-1 border rounded-md w-full sm:w-auto mb-3 lg:mb-0"
                        showYearDropdown
                        showMonthDropdown
                        onKeyDown={(e) => e.preventDefault()}
                      />

                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        startDate={startDate}
                        endDate={endDate}
                        selectsEnd
                        minDate={startDate}
                        popperPlacement="bottom-end"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="End Date"
                        disabled={!startDate}
                        className={`p-1 border rounded-md w-full sm:w-auto mb-3 lg:mb-0 ${
                          !startDate ? "opacity-45" : ""
                        }`}
                        showYearDropdown
                        showMonthDropdown
                        onKeyDown={(e) => e.preventDefault()}
                      />

                      <button
                        type="submit"
                        className={`lg:w-[20%] w-full bg-[#0368DE] rounded-lg px-4 py-1  text-white mb-3 lg:mb-0 ${
                          !startDate || !endDate ? "hidden" : ""
                        }`}
                      >
                        Proses
                      </button>
                    </div>
                    <div className="text-xs text-red-700 mb-3 mt-2 lg:mb-0">
                      {error && startDate.length <= 0 ? (
                        <label>Tanggal Tidak Boleh Kosong!!</label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </form>

              {dataComplaint.length > 0 ? (
                <div
                  id="recipients"
                  class="p-8 mt-6 lg:mt-0 rounded-xl shadow-xl bg-white border border-slate-100"
                >
                  <div className="overflow-x-auto relative">
                    <table
                      id="example11"
                      className="stripe hover text-sm lg:text-md"
                      style={{
                        width: "100%",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                      }}
                    >
                      <thead className="bg-primary text-white">
                        <tr>
                          <th
                            data-priority={1}
                            rowSpan={2}
                            className="sticky left-0 bg-primary"
                          >
                            NO.
                          </th>
                          <th
                            data-priority={2}
                            rowSpan={2}
                            className="sticky left-12 bg-primary"
                          >
                            NO.KOMPLAIN
                          </th>
                          <th data-priority={3} rowSpan={2}>
                            EMAIL
                          </th>
                          <th data-priority={4} rowSpan={2}>
                            NAMA DOKTER
                          </th>
                          <th data-priority={4} colSpan={3}>
                            TEMPAT PRAKTEK
                          </th>
                          <th data-priority={5} rowSpan={2}>
                            DESKRIPSI
                          </th>
                          <th data-priority={6} rowSpan={2}>
                            TANGGAL INPUT
                          </th>

                          <th data-priority={6} rowSpan={2}>
                            AKSI
                          </th>
                        </tr>
                        <tr>
                          <th data-priority={1}>JENIS</th>
                          <th data-priority={2}>NAMA</th>
                          <th data-priority={3}>ALAMAT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataComplaint.length === 0 ? (
                          <tr className="text-center font-inter ">
                            <td colSpan={"11"} className="py-6 text-lg">
                              {" "}
                              Data Belum Ada
                            </td>
                          </tr>
                        ) : (
                          <>
                            {dataComplaint?.map((list, index) => {
                              return (
                                <tr className="text-center" key={index}>
                                  <td className="sticky left-0 bg-white">
                                    {index + 1}.
                                  </td>
                                  <td className="sticky left-12 bg-white">
                                    {list.nomor}
                                  </td>
                                  <td>{list.email}</td>
                                  <td>{list.person_name}</td>
                                  <td>{list.channel_type_name}</td>
                                  <td>{list.location_name}</td>
                                  <td>{list.address_name}</td>
                                  <td>{list.description}</td>
                                  <td>{list.complaint_date}</td>
                                  <td>
                                    <button
                                      onClick={() =>
                                        handleDownload(
                                          list.id,
                                          list.nomor,
                                          list.ext
                                        )
                                      }
                                      className="bg-[#A14A76] hover:opacity-70 text-white text-[12px] lg:text-[15px] font-light py-1 px-1 md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-lg"
                                    >
                                      Unduh
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalArsip;
