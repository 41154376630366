import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoutes() {
  const hasToken = localStorage.getItem("token");

  return (
    <React.Fragment>
      {hasToken ? <Outlet /> : <Navigate replace to="/admin-sitapic" />}
      {/* <Outlet /> */}
    </React.Fragment>
  );
}

export default ProtectedRoutes;
