import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {
  PostContent,
  GetDetailContent,
} from "../../../service/admin/AdminService";
import Full from "../../../load/Laoding";

function ModalEditContent({ onCloseModal, idContent }) {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [file, setFile] = useState("");
  const [fileName, setNameFile] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const changeHandlerpdf = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];

      const fileName = selectedFile.name;
      const fileNameWithoutExtension = fileName.substring(
        0,
        fileName.lastIndexOf(".")
      );

      // Regular expression to check for invalid characters in the file name (excluding the extension)
      const invalidCharactersRegex = /[.,_\-=\+]/;

      if (
        !invalidCharactersRegex.test(fileNameWithoutExtension) &&
        validTypes.includes(fileType)
      ) {
        setFile(selectedFile);
      } else {
        // Clear the input value
        event.target.value = "";
        Swal.fire({
          title:
            "Hanya file dengan format PNG, JPG, atau JPEG yang diperbolehkan dan nama file tidak mengandung simbol ( . , _ - = + ) !!",
          showConfirmButton: false,
          icon: "warning",
        });
      }
    }
  };

  const sendModule = async () => {
    if (file.length == 0) {
      setError(true);
    }
    const MAX_FILE_SIZE = 5000; // 2MB
    const fileSizeKiloBytes = file.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File Size Maksimal 2MB!!");
      return;
    }

    showLoader();

    PostContent(idContent, file)
      .then((res) => {
        if (res.data.success === true) {
          onCloseModalProperty();
          Swal.fire({
            title: "Content berhasil ditambahkan!!",
            showConfirmButton: false,
            icon: "success",
          });
          hideLoader();
          window.location.reload();
        } else {
          onCloseModalProperty();
          Swal.fire({
            title: res.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          window.location = "/admin-sitapic";
          hideLoader();
        } else {
          Swal.fire({
            title: `${err.response.data.message}`,
            showConfirmButton: false,
            icon: "warning",
          });
        }
        hideLoader();
      });
  };

  useEffect(() => {
    if (idContent) {
      getDetailContent(idContent);
    }
  }, [idContent]);

  const getDetailContent = () => {
    showLoader();
    GetDetailContent(idContent)
      .then((res) => {
        if (res.data.success === true) {
          setNameFile(res.data.data.file_name);
        } else {
          Swal.fire({
            title: res.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
        }
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
      });
  };

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30"></div>
      <div
        tabIndex={-1}
        aria-hidden="true"
        className="overflow-y-auto overflow-x-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative p-2 w-full mx-auto my-[10%] max-w-lg max-h-full">
          {/* Modal content */}
          <div className="relative bg-white rounded-xl shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              {idContent ? (
                <h3 className="text-l font-semibold text-gray-900 dark:text-white text-center">
                  Perbarui Content
                </h3>
              ) : (
                <h3 className="text-l font-semibold text-gray-900 dark:text-white text-center">
                  Tambah Content
                </h3>
              )}

              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg w-2 h-2 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5">
              <form className="space-y-4" onSubmit={handleSubmit(sendModule)}>
                <div className="flex flex-wrap">
                  <label
                    htmlFor="jurnalFile"
                    className="w-[25%] block mb-2 pt-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Unggah Foto
                  </label>
                  <div className="relative w-[75%] h-[90px]">
                    <div className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-honeyYellow focus:border-honeyYellow flex items-center justify-between p-2.5 cursor-pointer">
                      <span className="truncate">
                        {file ? file.name : "Choose a file..."}
                      </span>
                      <input
                        type="file"
                        name="jurnalFile"
                        id="jurnalFile"
                        className="opacity-0 absolute inset-0 h-full w-full cursor-pointer"
                        onChange={changeHandlerpdf}
                        accept=".png,.jpg,.jpeg"
                      />
                      <button className="ml-2 text-honeyYellow">Browse</button>
                    </div>

                    {idContent ? (
                      <div className="">
                        <label className=" text-xs text-honeyYellow">
                          {fileName}
                        </label>
                      </div>
                    ) : null}
                    <div className="text-xs text-red-700 mb-3">
                      <label>{errorMsg}</label>
                      {error && file.length <= 0 ? (
                        <label>File Tidak Boleh Kosong!</label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                {idContent ? (
                  <div className="flex justify-center text-white ">
                    <button
                      type="submit"
                      className="w-1/2 bg-primary rounded-full px-5 py-2  "
                    >
                      Update
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center text-white">
                    <button
                      type="submit"
                      className="w-1/2 bg-primary rounded-lg px-5 py-2  "
                    >
                      Tambahkan
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalEditContent;
