import React, { useState, useEffect } from "react";
import { getDataImageNakes } from "../service/page/PageService";
import Full from "../load/Laoding";
import Swal from "sweetalert2";

const Nakes = () => {
  //loading
  const [loader, showLoader, hideLoader] = Full();
  const [dataImage, setDataImage] = useState([]);

  useEffect(() => {
    GetDataImage();
  }, []);

  const GetDataImage = () => {
    showLoader();
    getDataImageNakes()
      .then((res) => {
        setDataImage(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          showConfirmButton: false,
          icon: "warning",
        });
        hideLoader();
      });
  };

  return (
    <>
      {/* hero */}
      <div className="bg-custom-pattern2 bg-cover bg-center h-[36vh] w-full md:h-[50vh] lg:h-[100vh] flex items-end justify-start">
        <h1 className="text-[16px] lg:text-[36px] font-semibold mb-4 text-white lg:pl-12 lg:pb-8 mx-0 pl-6 lg:mx-0 text-shadow-md font-inter">
          Informasi Pada Halaman Ini Khusus <br />
          Untuk Tenaga Kesehatan.
        </h1>
      </div>

      {/* sitapic */}
      <section>
        <h1 className="text-center text-primary text-xl lg:text-[35px] font-bold tracking-wide mt-3 lg:mt-12  font-inter">
          SITAPIC
        </h1>
        <h5 className="text-[14px] lg:text-[18px] text-center  font-reguler font-inter  py-2">
          MORE SAFE MORE VERSATILE
        </h5>
        <h5 className="text-[12px] lg:text-[16px] text-center  font-bold font-inter py-1 px-3">
          Sitapic mengandung Sitagliptin Phosphate 128.5 mg dalam bentuk kaplet
          salut selaput.
        </h5>
        {dataImage.length > 0 && (
          <>
            <div className="lg:my-12 lg:mx-28 mx-3 my-3">
              <h5 className="text-[16px] lg:text-[24px] lg:pb-3 pb-2 text-primary font-semibold font-inter ">
                FARMAKOLOGI
              </h5>
              <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
              <img
                src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[0].content_id}`}
                alt="kalpika logo"
                className="lg:ml-auto w-full h-full lg:max-w-[350px] lg:max-h-[300px] float-left lg:float-left mb-1 mr-4"
              />
              <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter">
                Sitagliptin adalah antidiabetes oral yang bekerja sebagai
                inhibitor DPP-4 (Dipeptidyl peptidase IV) yang bekerja dengan
                memperlambat inaktivasi hormon-hormon incretin. Penghambatan
                DPP-4 (Dipeptidyl peptidase IV) oleh sitagliptin, akan
                meningkatkan dan memperpanjang kerja homon-hormon incretin.
                Hormon incretin, termasuk glucagon-like peptide-1 (GLP-1) dan
                glucose-dependent insulinotropic polypeptide (GIP), dilepaskan
                oleh usus sepanjang hari, dan kadarnya meningkat sebagai respons
                terhadap makanan yang masuk. Hormon-hormon incretin akan cepat
                diinaktivasi oleh enzim, DPP-4. Hormon-hormon incretin adalah
                bagian dari sistem endogen yang terlibat dalam regulasi
                fisiologis homeostasis glukosa. Ketika konsentrasi glukosa darah
                normal atau meningkat, GLP-1 dan GIP meningkatkan sintesis dan
                pelepasan insulin dari sel beta pankreas melalui jalur sinyal
                intraseluler yang melibatkan AMP siklik. GLP-1 juga menurunkan
                sekresi glukagon dari sel alfa pankreas, yang menyebabkan
                penurunan produksi glukosa hati. Dengan meningkatkan dan
                memperpanjang kadar incretin aktif, sitagliptin meningkatkan
                pelepasan insulin dan menurunkan kadar glukagon dalam sirkulasi
                dengan cara bergantung pada glukosa. Sitagliptin menunjukkan
                selektivitas untuk DPP-4 dan tidak menghambat aktivitas DPP-8
                atau DPP-9 secara in vitro pada konsentrasi yang mendekati dosis
                terapeutik.
              </p>
            </div>

            <div className="lg:my-12 lg:mx-28 mx-3 my-3">
              <h5 className="text-[16px] lg:text-[24px] lg:pb-3 pb-2 text-primary font-semibold font-inter ">
                FARMAKOKINETIKA
              </h5>
              <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
              <img
                src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[1].content_id}`}
                alt="kalpika logo"
                className="lg:ml-auto w-full h-full lg:max-w-[350px] lg:max-h-[300px] float-right lg:float-right mb-2 lg:pl-3"
              />
              <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter">
                Tablet salut selaput SITAGLIPTIN PHOSPHATE telah diuji dengan
                desain secara open-label, acak, dosis tunggal, dua periode, dua
                sekuens, cross-over pada kondisi puasa yang melibatkan total 24
                subjek pria dan wanita dewasa yang sehat (rentang usia 18-55
                tahun). Parameter utama yang digunakan untuk menilai
                bioekuivalensi antara obat uji dan obat komparator yaitu AUC0-t
                dan Cmaks dari sitagliptin. Bioekuivalensi dari kedua produk
                ditentukan berdasarkan 90% confidence intervals dari rasio
                rata-rata geometrik obat uji/obat komparator untuk AUC0-t dan
                Cmaks berada dalam rentang 80,00-125,00%. Setelah pemberian oral
                tablet salut selaput SITAGLIPTIN PHOSPHATE 100 mg, nilai
                rata-rata AUC0-t dan AUC0-inf sitagliptin masing-masing sebesar
                4.001,27 ng.jam/ml dan 4.207,15 ng.jam/ml. Nilai rata-rata kadar
                maksimum plasma (Cmaks) sitagliptin adalah 470,90 ng/ml dengan
                nilai median tmaks adalah 2,00 (0,50-5,00) jam. Rata-rata waktu
                paruh (t1/2) sitagliptin adalah 7,88 jam. Rasio rata-rata
                geometrik (90% confidence intervals) dari sitagliptin adalah
                100,30% (94,64%- 106,29%) untuk AUC0-1 dan 102,66%
                (95,63%-110.22%) untuk Cmaks. Hasil dari penelitian ini
                menunjukkan bahwa parameter farmakokinetika dari tablet salut
                selaput SITAGLIPTIN PHOSPHATE berada dalam rentang nilai
                parameter bioekuivalensi, sehingga tablet salut selaput
                SITAGLIPTIN PHOSPHATE serupa atau bioekuivalen dengan obat
                komparator.
              </p>
            </div>

            <div className="lg:my-12 lg:mx-28 mx-3 my-3">
              <h5 className="text-[16px] lg:text-[24px] lg:pb-3 pb-2 text-primary font-semibold font-inter">
                INDIKASI TERAPI
              </h5>
              <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
              <img
                src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[2].content_id}`}
                alt="kalpika logo"
                className="lg:ml-auto w-full h-full lg:max-w-[350px] lg:max-h-[300px] float-left lg:float-left mb-1 mr-4"
              />
              <div>
                <h5 className="text-[14px] lg:text-[20px] lg:pb-3 text-black font-semibold font-inter ">
                  1. Monoterapi
                </h5>
                <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter lg:mb-6 mb-3">
                  Sitagliptin dindikasikan sebagai tambahan terhadap diet dan
                  olahraga untuk meningkatkan kontrol glikemik pada pasien
                  dengan diabetes melitus tipe 2.
                </p>
                <h5 className="text-[14px] lg:text-[20px] lg:pb-3 text-black font-semibold font-inter ">
                  2. Kombinasi OAD Lain
                </h5>
                <ul className="text-[14px] lg:text-[20px] text-justify font-regular font-inter lg:mb-6 mb-3 list-disc list-inside">
                  <li className="pb-2">
                    Kombinasi dengan sulfonilurea, metformin atau agonis PPARɣ
                    (yaitu thiazolidinedione) diberikan ketika obat tunggal
                    saja, dengan diet dan olahraga, tidak memberikan kontrol
                    glikemik yang memadai.
                  </li>
                  <li className="lg:pb-2">
                    Kombinasi dengan metformin dan sulfonylurea diberikan ketika
                    terapi ganda dengan obat-obat tersebut, dengan diet dan
                    olahraga tidak memberikan kontrol glikemik yang memadai.
                  </li>
                </ul>
                <h5 className="text-[14px] lg:text-[20px] lg:pb-3 text-black font-semibold font-inter ">
                  3. Kombinasi dengan insulin
                </h5>
                <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter mb-3 lg:mb-6">
                  Sitagliptin juga dindikasikan sebagai tambahan pada insulin
                  (dengan atau tanpa metformin) ketika diet dan olahraga
                  ditambah dosis insulin yang stabil tidak memberikan kontrol
                  glikemik yang memadai.
                </p>
              </div>
            </div>

            <div className="lg:my-12 lg:mx-28 mx-3 my-3">
              <h5 className="text-[16px] lg:text-[24px] lg:pb-3 pb-2 text-primary font-semibold font-inter ">
                ATURAN MINUM
              </h5>
              <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
              <img
                src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[3].content_id}`}
                alt="kalpika logo"
                className="lg:ml-auto w-full h-full lg:max-w-[350px] lg:max-h-[300px] float-right lg:float-right mb-2 lg:pl-3"
              />
              <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter">
                Dosis sitagliptin yang dianjurkan adalah 100 mg sekali sehari
                sebagai monoterapi atau sebagai terapi kombinasi dengan
                metformin atau agonis PPARɣ (misalnya thiazolidinedione),
                metformin dan sulfonylurea, dosis insulin yang stabil (dengan
                atau tanpa metformin). Sitagliptin dapat dikonsumsi dengan atau
                tanpa makanan. Ketika digunakan dalam kombinasi dengan metformin
                atau agonis PPARgɣ dosis metformin atau agonis PPARɣ harus
                dipertahankan, dan sitagliptin diberikan secara bersamaan.
                Ketika sitagliptin digunakan dalam kombinasi dengan sulfonylurea
                atau dengan insulin, dosis sulfonylurea atau insulin yang lebih
                rendah dapat dipertimbangkan untuk mengurangi risiko
                hipoglikemia.
              </p>
            </div>

            <div className="lg:my-12 lg:mx-28 mx-3 my-3">
              <h5 className="text-[16px] lg:text-[24px] lg:pb-3 pb-2 text-primary font-semibold font-inter ">
                PERINGATAN
              </h5>
              <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
              <img
                src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[4].content_id}`}
                alt="kalpika logo"
                className="lg:ml-auto w-full h-full lg:max-w-[350px] lg:max-h-[300px] float-left lg:float-left mb-2 lg:pr-3"
              />
              <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter">
                Sitagliptin tidak boleh digunakan pada pasien dengan diabetes
                tipe 1 atau untuk pengobatan diabetes ketoasidosis.
              </p>
            </div>

            <div className="lg:pt-[150px] lg:mx-28 mx-3 my-3 lg:my-28">
              <h5 className="text-[16px] lg:text-[24px] lg:pb-3 pb-2 text-primary font-semibold font-inter">
                INTERAKSI OBAT
              </h5>
              <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
              <img
                src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[5].content_id}`}
                alt="kalpika logo"
                className="lg:ml-auto w-full h-full lg:max-w-[350px] lg:max-h-[300px] float-right lg:float-right mb-1 lg:pl-4"
              />
              <div>
                <h5 className="text-[14px] lg:text-[20px] lg:pb-3 text-black font-semibold font-inter ">
                  1. Efek sitagliptin terhadap obat lain
                </h5>
                <ul className="text-[14px] lg:text-[20px] text-justify font-regular font-inter lg:mb-6  list-disc list-inside">
                  <li className="pb-2">
                    Sitagliptin tidak memengaruhi profil farmakokinetika
                    metformin, glyburide, simvastatin, rosiglitazone, warfarin,
                    atau kontrasepsi oral. Sitagliptin bukan merupakan inhibitor
                    isoenzim CYP3A4, CYP2C8, CYP2C9 dan transporter kation
                    organik [organic cationic transporter (OCT)].
                  </li>
                  <li className="pb-2">
                    Digoxin Sitagliptin memiliki efek minimal terhadap
                    farmakokinetika digoxin. Pasien yang mendapat pengobatan
                    digoxin harus dipantau dengan saksama. Tidak perlu dilakukan
                    penyesuaian dosis digoxin ataupun sitagliptin.
                  </li>
                  <li className="pb-2">
                    Metformin Pemberian beberapa dosis sitagliptin dua kali
                    sehari bersamaan dengan metformin, suatu substrat OCT, tidak
                    mengubah farmakokinetika metformin secara bermakna pada
                    pasien diabetes tipe 2. Oleh karena itu, sitagliptin bukan
                    inhibitor transport yang dimediasi OCT.
                  </li>
                  <li className="pb-2">
                    Sulfonylurea Farmakokinetika dosis tunggal glyburide, suatu
                    substrat CYP2C9, tidak berubah secara bermakna pada pasien
                    yang mendapat beberapa dosis sitagliptin. Perubahan bermakna
                    juga diperkirakan tidak terjadi dengan sulfonylurea lainnya
                    (seperti glipizide, tolbutamide, dan glimepiride), yang sama
                    seperti glyburide, utamanya dieliminasi oleh CYP2C9. Namun,
                    risiko hipoglikemia dari pemberian sitagliptin bersamaan
                    dengan sulfonyfurea belum diketahui.
                  </li>
                  <li className="pb-2">
                    Simvastatin Farmakokinetika dosis tunggal simvastatin, suatu
                    substrat CYP3A4, tidak berubah secara bermakna dengan
                    pemberian sitagliptin beberapa dosis sehari. Oleh karena
                    itu, sitagliptin bukan inhibitor metabolisme yang dimediasi
                    CYP3A4.
                  </li>
                  <li className="pb-2">
                    Warfarin Pemberian sitagliptin beberapa dosis sehari tidak
                    memengaruhi profil farmakokinetika atau farmakodinamik
                    warfarin dosis tunggal secara bermakna. Karena enansiomer
                    S(-) warfarin utamanya dimetabolisme oleh CYP2C9, data
                    tersebut mendukung kesimpulan bahwa sitagliptin bukan
                    inhibitor CYP2C9.
                  </li>
                </ul>
              </div>
              <img
                src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[6].content_id}`}
                alt="kalpika logo"
                className="lg:ml-auto w-full h-full lg:max-w-[350px] lg:max-h-[300px] float-left lg:float-left mr-4 mb-2"
              />
              <div>
                <h5 className="text-[14px] lg:text-[20px] lg:pb-3 text-black font-semibold font-inter ">
                  2. Efek obat lain terhadap sitagliptin
                </h5>
                <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter lg:mb-6 mb-3">
                  Metformin Pemberian beberapa dosis metformin dua kali sehari
                  bersamaan dengan sitagliptin tidak mengubah farmakokinetika
                  sitagliptin secara bermakna pada pasien diabetes tipe 2.
                  Ciclosporin Pemberian dosis tunggal 100 mg oral sitagliptin
                  dan dosis tunggal 600 mg oral
                  ciclosporin,suatuinhibitorkuatp-glycoprotein, meningkatkan AUC
                  dan Cmaks sitagliptin tetapi perubahan parameter tersebut
                  dinilai tidak bermakna secara klinis. Bersihan renal
                  sitagliptin juga tidak berubah secara bermakna. Oleh karena
                  itu, diperkirakan juga tidak terjadi interaksi dengan
                  inhibitor p-glycoprotein lainnya.
                </p>
                <h5 className="text-[14px] lg:text-[20px] lg:pb-3 text-black font-semibold font-inter ">
                  3. Kontrasepsi oral
                </h5>
                <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter lg:mb-6">
                  Pemberian bersama dengan sitagliptin tidak mengubah
                  farmakokinetika keadaan tunak norethindrone atau ethinyl
                  estradiol secara bermakna.
                </p>
              </div>
            </div>
          </>
        )}
      </section>

      {/* penjelasan sitapic */}
      <section className="lg:pt-[3px] mb-8 mx-3">
        <div className="lg:my-12 lg:mx-28">
          <h1 className="text-center text-primary text-md lg:text-[28px] font-semibold tracking-wide mt-6 mb-2 lg:mb-[24px] font-gilroyBold">
            POPULASI KHUSUS PENGGUNA SITAPIC
          </h1>
          <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
          <h5 className="text-[16px] lg:text-[24px] lg:pb-3 text-black font-semibold font-inter ">
            1. Kehamilan dan Menyusui
          </h5>
          <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter lg:mb-6 mb-3">
            Tablet salut selaput SITAGLIPTIN PHOSPHATE telah diuji dengan desain
            secara open-label, acak, dosis tunggal, dua periode, dua sekuens,
            cross-over pada kondisi puasa yang melibatkan total 24 subjek pria
            dan wanita dewasa yang sehat (rentang usia 18-55 tahun). Parameter
            utama yang digunakan untuk menilai bioekuivalensi antara obat uji
            dan obat komparator yaitu AUC0-t dan Cmaks dari sitagliptin.
            Bioekuivalensi dari kedua produk ditentukan berdasarkan 90%
            confidence intervals dari rasio rata-rata geometrik obat uji/obat
            komparator untuk AUC0-t dan Cmaks berada dalam rentang
            80,00-125,00%. Setelah pemberian oral tablet salut selaput
            SITAGLIPTIN PHOSPHATE 100 mg, nilai rata-rata AUC0-t dan AUC0-inf
            sitagliptin masing-masing sebesar 4.001,27 ng.jam/ml dan 4.207,15
            ng.jam/ml.
          </p>
          <h5 className="text-[16px] lg:text-[24px] lg:pb-3 text-black font-semibold font-inter ">
            2. Pasien dengan gangguan fungsi ginjal
          </h5>
          <p className="text-[14px] lg:text-[20px] text-justify font-regular font-inter">
            Nilai rata-rata kadar maksimum plasma (Cmaks) sitagliptin adalah
            470,90 ng/ml dengan nilai median tmaks adalah 2,00 (0,50-5,00) jam.
            Rata-rata waktu paruh (t1/2) sitagliptin adalah 7,88 jam. Rasio
            rata-rata geometrik (90% confidence intervals) dari sitagliptin
            adalah 100,30% (94,64%- 106,29%) untuk AUC0-1 dan 102,66%
            (95,63%-110.22%) untuk Cmaks. Hasil dari penelitian ini menunjukkan
            bahwa parameter farmakokinetika dari tablet salut selaput
            SITAGLIPTIN PHOSPHATE berada dalam rentang nilai parameter
            bioekuivalensi, sehingga tablet salut selaput SITAGLIPTIN PHOSPHATE
            serupa atau bioekuivalen dengan obat komparator.
          </p>
        </div>
      </section>

      {/* event */}
      <section className="lg:pt-[5px] mx-3 lg:mx-0">
        <div className="grid lg:grid-cols-6 gap-2">
          {/* Content for the first column - Image (3 columns) */}
          <div className="lg:col-span-full col-span-full mx-auto lg:mt-0 text-center ">
            <h1 className="text-center text-primary text-md lg:text-[28px] font-semibold tracking-wide lg:mb-[18px] font-inter">
              UNDUH INFORMASI OBAT SITAPIC
            </h1>
            <div className="border-t-2 border-slate-950 lg:mb-4 w-full"></div>
            <div className="grid lg:grid-cols-1">
              {/* Content for the first column - Button */}
              <a
                href={`https://be.sitapic.com/landing/public/api/content/leaflet`}
                target="_blank"
                download="informasi.pdf"
              >
                <div className="lg:col-span-full col-span-full mx-0 mt-8 lg:mt-0 ">
                  <button className="bg-[#A14A76] w-full text-white rounded-md uppercase hover:bg-[#1AA179] font-inter font-bold p-2 text-[10px] md:text-[14px] md:p-4 lg:px-24 lg:py-3 lg:text-[18px]">
                    UNDUH INFORMASI
                  </button>
                </div>
              </a>
            </div>
          </div>
          {/* <div className="lg:col-span-6 col-span-full mx-auto lg:mt-0 text-center ">
            <h1 className="text-center text-primary text-md lg:text-[28px] font-semibold tracking-wide lg:mb-[18px] font-inter">
              CLINICAL EVIDENCE DAN LAYANAN PENGADUAN
            </h1>
            <div className="border-t-2 border-slate-950 lg:mb-4 w-full"></div>
            <div className="grid lg:grid-cols-1">
            
              <Link to="/jurnal">
                <div className="lg:col-span-full col-span-full mx-0 mt-8 lg:mt-0 ">
                  <button className="bg-[#1E6C93] w-full text-white rounded-md uppercase hover:bg-[#1AA179] font-inter font-bold p-2 text-[10px] md:text-[14px] md:p-4 lg:px-24 lg:py-3 lg:text-[18px]">
                    CLINICAL EVIDENCE & LAYANAN PENGADUAN
                  </button>
                </div>
              </Link>
            </div>
          </div> */}
        </div>
      </section>
      {loader}
    </>
  );
};

export default Nakes;
