import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Full from "../load/Laoding";
import Swal from "sweetalert2";
import { getDataImage, getDataEvent } from "../service/page/PageService";
import ModalEvent from "./ModalEvent";
const LandingPage = () => {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [modalIsOpenView, setModalIsOpenView] = useState(false);
  const [idModal, setIdModal] = useState("");
  const [nameModal, setNameModal] = useState("");

  const openModal = (id, name) => {
    setModalIsOpenView(true);
    setIdModal(id);
    setNameModal(name);
  };

  const closeModalView = () => {
    setModalIsOpenView(false);
  };

  const [dataImage, setDataImage] = useState([]);
  const [dataEvent, setDataEvent] = useState([]);

  useEffect(() => {
    GetDataImage();
    GetDataEvent();
  }, []);

  const GetDataImage = () => {
    showLoader();
    getDataImage()
      .then((res) => {
        setDataImage(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          showConfirmButton: false,
          icon: "warning",
        });
        hideLoader();
      });
  };

  const GetDataEvent = () => {
    showLoader();
    getDataEvent()
      .then((res) => {
        setDataEvent(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          showConfirmButton: false,
          icon: "warning",
        });
        hideLoader();
      });
  };

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* hero */}
      <div className="bg-custom-pattern bg-cover bg-center h-[36vh] w-full md:h-[50vh] lg:h-[100vh] flex items-end justify-center">
        <Link to="/profesional">
          <button className="bg-[#1E6C93] text-white rounded-md uppercase hover:bg-[#1AA179] hover:text-white font-inter font-bold p-2 text-[10px] md:text-[14px] md:p-4 lg:px-24 lg:py-6 lg:text-[21px] mb-4 lg:mb-12">
            Profesional Kesehatan Klik Di Sini
          </button>
        </Link>
      </div>

      {/* produk */}
      <section id="perusahaan" className="pt-[10px] ">
        {dataImage.length > 0 && (
          <>
            <div className="grid lg:grid-cols-12 gap-2 lg:my-20 lg:mx-28">
              {/* Content for the first column - Image (3 columns) */}
              <div className="lg:col-span-4 col-span-full mx-auto mt-8 lg:mt-0 flex justify-start">
                <img
                  src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[0].content_id}`}
                  alt="kalpika logo"
                  className="lg:ml-auto w-full h-full lg:max-w-[550px] lg:max-h-[300px] px-2"
                />
              </div>

              {/* Content for the second column - Text (9 columns) */}
              <div className="lg:col-span-8 col-span-full mx-2">
                <h5 className="text-[14px] lg:text-[20px] text-justify font-regular font-inter lg:py-12 py-6">
                  <b className="font-bold">SITAPIC</b> mengandung Sitagliptin
                  yang merupakan obat antidiabetik oral yang digunakan untuk
                  mengontrol kadar gula darah pada pasien dengan diabetes
                  mellitus tipe 2. Sitapic bekerja dengan cara menghambat enzim
                  DPP-4, sehingga meningkatkan kadar incretin dalam tubuh yang
                  membantu menurunkan kadar HbAc1 secara signifikan pada pasien
                  Diabetes Melitus tipe 2.
                </h5>
              </div>
            </div>

            <div className="grid lg:grid-cols-12 gap-8 lg:my-20 lg:mx-32">
              {/* Content for the second column */}
              <div className="lg:col-span-4 col-span-full mx-auto lg:mt-0 lg:hidden">
                {" "}
                <img
                  src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[1].content_id}`}
                  alt="kalpika logo"
                  className="lg:ml-auto w-full h-full lg:max-w-[550px] lg:max-h-[300px] px-2"
                ></img>
              </div>
              <div className="lg:col-span-8 col-span-full mx-2">
                <label className="text-[16px] lg:text-[24px]  text-justify  font-semibold font-inter text-primary ">
                  KEMASAN
                </label>
                <h5 className="text-[14px] lg:text-[20px]  text-justify  font-reguler font-inter pt-2 mt-2 lg:pt-6 lg:mt-2  border-t-2 border-black">
                  Box isi 28 kaplet salut selaput, tiap kaplet mengandung
                  Sitagliptin Phosphate 128.5 mg, di simpan pada suhu dibawah
                  30°C.
                </h5>
              </div>
              {/* Content for the first column */}
              <div className="lg:col-span-4 col-span-full mx-auto  lg:mt-0  justify-end lg:block hidden">
                {" "}
                <img
                  src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[1].content_id}`}
                  alt="kalpika logo"
                  className="lg:ml-auto w-full h-full lg:max-w-[550px] lg:max-h-[300px] px-2"
                ></img>
              </div>
            </div>
            <div className="grid lg:grid-cols-12 gap-2 lg:my-20 lg:mx-28 ">
              {/* Content for the first column - Image (3 columns) */}
              <div className="lg:col-span-4 col-span-full mx-auto  mt-8 lg:mt-0 flex justify-start ">
                <img
                  src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[2].content_id}`}
                  alt="kalpika logo"
                  className="lg:ml-auto w-full h-full lg:max-w-[550px] lg:max-h-[250px] px-2"
                />
              </div>

              {/* Content for the second column - Text (9 columns) */}
              <div className="lg:col-span-8 col-span-full mx-2  my-6">
                <label className="text-[16px] lg:text-[24px]  text-justify  font-semibold font-inter text-primary ">
                  ATURAN MINUM
                </label>
                <h5 className="text-[14px] lg:text-[20px] text-justify font-regular font-inter pt-4 mt-2 lg:pt-6 lg:mt-2  border-t-2 border-black">
                  setiap hari sesuai resep yang diberikan dokter. Anda dapat
                  meminum <b className="font-bold">SITAPIC</b> sebelum atapun
                  sesudah makanan. Dokter mungkin akan meresepkan{" "}
                  <b className="font-bold">SITAPIC</b> bersamaan dengan Obat
                  Anti Diabetes lainnya. Jika Anda melewatkan satu dosis,
                  minumlah segera setelah Anda ingat. Jika Anda tidak ingat
                  sampai tiba waktunya untuk dosis berikutnya, lewati dosis yang
                  terlewat dan kembali ke jadwal rutin Anda. Jangan minum dua
                  dosis <b className="font-bold">SITAPIC</b> secara bersamaan.
                  Jika Anda mengonsumsi <b className="font-bold">SITAPIC</b>{" "}
                  terlalu banyak, segera hubungi dokter atau Pusat Kesehatan
                  terdekat.
                </h5>
              </div>
            </div>

            <div className="grid lg:grid-cols-12 gap-8 lg:my-20 lg:mx-32">
              {/* Content for the second column */}
              <div className="lg:col-span-4 col-span-full mx-auto  mt-8 lg:mt-0 flex justify-end lg:hidden">
                {" "}
                <img
                  src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[3].content_id}`}
                  alt="kalpika logo"
                  className="lg:ml-auto w-full h-full lg:max-w-[550px] lg:max-h-[300px] px-2"
                ></img>
              </div>

              <div className="lg:col-span-8 col-span-full mx-2 ">
                <label className="text-[16px] lg:text-[24px]  text-justify  font-semibold font-inter text-primary ">
                  SITAPIC DAN HIPOGLIKEMIK
                </label>
                <h5 className="text-[14px] lg:text-[20px]  text-justify  font-reguler font-inter pt-4 mt-2 lg:pt-6 lg:mt-2 border-t-2 border-black">
                  <b className="font-bold">SITAPIC</b> tanpa kombinasi, tidak
                  mungkin menyebabkan hipoglikemia, Bila Anda menggunakan{" "}
                  <b className="font-bold">SITAPIC</b> dengan obat lain yang
                  dapat menyebabkan hipoglikemi atau gula darah rendah, seperti
                  sulfonilurea atau insulin, risiko Anda terkena hipoglikemi
                  atau gula darah rendah akan semakin tinggi. Perlu penyesuaian
                  dosis obat sulfonilurea atau insulin Anda selama Anda
                  menggunakan <b className="font-bold">SITAPIC</b>.
                </h5>
              </div>
              {/* Content for the first column */}
              <div className="lg:col-span-4 col-span-full mx-auto  mt-8 lg:mt-0 lg:block hidden ">
                {" "}
                <img
                  src={`https://be.sitapic.com/landing/public/api/content/file/${dataImage[3].content_id}`}
                  alt="kalpika logo"
                  className="lg:ml-auto w-full h-full lg:max-w-[550px] lg:max-h-[300px] px-2"
                ></img>
              </div>
            </div>
          </>
        )}
      </section>

      {/* button tenaga kesehatan */}
      <section className="lg:pt-[5px] mb-8">
        <h1 className="text-center text-primary text-[16px] lg:text-[24px] font-semibold tracking-wide mt-6 lg:mb-[50px] font-inter">
          UNTUK TENAGA KESEHATAN
        </h1>
        <div className="grid lg:grid-cols-1 lg:mx-32 mx-2">
          {/* Content for the first column - Button */}
          <Link to="/profesional">
            <div className="lg:col-span-full col-span-full mx-0 mt-8 lg:mt-0 flex justify-center">
              <button className="bg-[#1E6C93] w-full text-white rounded-md uppercase hover:bg-[#1AA179] font-inter font-bold p-2 text-[10px] md:text-[14px] md:p-4 lg:px-24 lg:py-3 lg:text-[18px]">
                KLIK DI SINI
              </button>
            </div>
          </Link>
        </div>
      </section>

      {/* event */}
      <section className="lg:pt-[5px] mx-2">
        <div className="grid lg:grid-cols-1 gap-2 lg:mt-20 lg:mx-32 border-b-2 border-black">
          {/* Content for the second column - Text (9 columns) */}
          <div className="lg:col-span-1 col-span-full lg:py-2">
            <label className="text-[16px] lg:text-[24px] text-justify font-semibold font-inter text-primary lg:py-12 ">
              EVENT TERKAIT
            </label>
          </div>
        </div>

        <div
          className={`grid lg:grid-cols-12 gap-2 lg:my-12 lg:mx-28 ${
            dataEvent.length > 3 ? "max-h-96 overflow-y-auto" : ""
          }`}
        >
          {/* Content for the first column - Image (3 columns) */}
          {dataEvent.length === 0 ? (
            <div className="lg:col-span-full col-span-full mx-auto m-12 lg:mt-0 flex justify-start">
              <h5 className="text-center font-inter text-[16px] lg:text-[20px] font-bold">
                Belum Ada Event
              </h5>
            </div>
          ) : (
            <>
              {" "}
              {dataEvent.map((list, index) => {
                return (
                  <div
                    key={index}
                    className="lg:col-span-4 col-span-full mx-auto m-12 lg:mt-0 flex justify-start"
                  >
                    <div
                      className="max-w-md bg-white border-none cursor-pointer"
                      onClick={() => openModal(list.id, list.event_name)}
                    >
                      <img
                        className="rounded-lg lg:ml-auto w-full h-full lg:max-w-[550px] lg:max-h-[300px] hover:opacity-65"
                        src={`https://be.sitapic.com/landing/public/api/event/file/${list.id}`}
                        alt=""
                      />
                      <div className="text-center py-5">
                        <h5 className="mb-2 text-md lg:text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                          {list.event_name}
                        </h5>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </section>

      {modalIsOpenView ? (
        <ModalEvent
          onCloseModal={closeModalView}
          idModal={idModal}
          nameModal={nameModal}
        />
      ) : null}

      {loader}
    </>
  );
};

export default LandingPage;
