import React, { useState, useEffect } from "react";
import Full from "../../load/Laoding";
import { getListEvent } from "../../service/admin/AdminService";
import ModalAddEvent from "../admin/modal/ModalAddEvent";
import Swal from "sweetalert2";

const MonitorEvent = () => {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [dataEvent, setDataEvent] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idEvent, setIdEvent] = useState("");

  useEffect(() => {
    GetDataEvent();
  }, []);

  const GetDataEvent = () => {
    showLoader();
    getListEvent()
      .then((res) => {
        setDataEvent(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          window.location = "/admin-sitapic";
          localStorage.removeItem("token");
          hideLoader();
        } else {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        }
      });
  };

  const openModalAddEvent = () => {
    setModalIsOpen(true);
    setIdEvent(null);
  };

  const openModalAEditEvent = (id) => {
    setModalIsOpen(true);
    setIdEvent(id);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (dataEvent.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(function () {
      $("#example3")
        .DataTable({
          responsive: false,
          info: false,
          lengthChange: false,
        })
        .columns.adjust()
        .responsive.recalc();
    });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [dataEvent]);

  return (
    <>
      <div className="mt-12">
        <h5 className="lg:text-[30px] text-md font-inter font-bold">
          Monitoring Event
        </h5>
        <div className="grid grid-cols-1 gap-2 lg:pb-4 ">
          <div className="col-span-1 flex justify-end items-center">
            {/* Button aligned to the right in the second column */}
            <button
              onClick={openModalAddEvent}
              className="bg-[#0368DE] hover:opacity-80 text-white font-bold py-2 px-4 rounded text-sm lg:text-md"
            >
              Tambah Event
            </button>
          </div>
        </div>
        <div
          id="recipients"
          class="p-8 mt-6 lg:mt-0 rounded-xl shadow-xl bg-white border border-slate-100"
        >
          <div className="overflow-x-auto relative">
            <table
              id="example3"
              className="stripe hover text-sm lg:text-md"
              style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}
            >
              <thead className="bg-primary text-white">
                <tr>
                  <th data-priority={1} className="sticky left-0 bg-primary">
                    NO.
                  </th>
                  <th data-priority={2} className="sticky left-12 bg-primary">
                    JUDUL EVENT
                  </th>
                  <th data-priority={3}>POSTER EVENT</th>
                  <th data-priority={4}>PERIODE TAYANG</th>
                  <th data-priority={5}>AKSI</th>
                </tr>
              </thead>
              <tbody>
                {dataEvent.length === 0 ? (
                  <tr className="text-center font-inter ">
                    <td colSpan={"5"} className="py-6 text-lg">
                      {" "}
                      Data Belum Ada
                    </td>
                  </tr>
                ) : (
                  <>
                    {" "}
                    {dataEvent?.map((list, index) => {
                      return (
                        <tr className="text-center" key={index}>
                          <td className="sticky left-0 bg-white">
                            {index + 1}.
                          </td>
                          <td className="sticky left-12 bg-white">
                            {list.event_name}
                          </td>
                          <td>{list.file_name}</td>
                          <td>
                            {list.start_date} s/d {list.end_date}
                          </td>
                          <td>
                            <button
                              onClick={() => openModalAEditEvent(list.id)}
                              className="bg-[#A14A76] hover:opacity-70 text-white text-[12px] lg:text-[15px] font-light py-1 px-1  md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-lg "
                            >
                              Perbarui
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {modalIsOpen ? (
        <ModalAddEvent onCloseModal={closeModal} idEvent={idEvent} />
      ) : null}
    </>
  );
};

export default MonitorEvent;
