import React from "react";
import MonitorPengaduan from "./MonitorPengaduan";
import MonitorJurnal from "./MonitorJurnal";
import MonitorEvent from "./MonitorEvent";
import MonitorLanding from "./MonitorLanding";

const Dashboard = () => {
  return (
    <>
      <div className="mx-3 my-3">
        <MonitorPengaduan></MonitorPengaduan>
        <MonitorJurnal></MonitorJurnal>
        <MonitorEvent></MonitorEvent>
        <MonitorLanding></MonitorLanding>
      </div>
    </>
  );
};

export default Dashboard;
