import { Route, Routes, BrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./route/ProtecedRoute";
import Home from "./auth/Home";
import HomeLand from "./auth/HomeLand";
import LandingPage from "./component/LandingPage";
import Profesional from "./component/Profesional";
import Nakes from "./component/Nakes";
import JurnalPengaduan from "./component/JurnalPengaduan";
import Pengaduan from "./component/Pengaduan";
import Login from "./component/admin/Login";
import Dashboard from "./component/admin/Dashboard";
import ErrorPage from "./template/ErrorPage";

const AppRouter = () => {
  return (
    <Routes history={BrowserRouter}>
      <Route element={<HomeLand />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/profesional" element={<Profesional />} />
        <Route path="/nakes" element={<Nakes />} />
        <Route path="/jurnal" element={<JurnalPengaduan />} />
        <Route path="/pengaduan" element={<Pengaduan />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route element={<Home />}>
          <Route path="/dashboard-sitapic" element={<Dashboard />} />
        </Route>
      </Route>
      <Route path="/admin-sitapic" element={<Login />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default AppRouter;
