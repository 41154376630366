import React from "react";

export default function RootLayout() {
  const currentTime = new Date().getFullYear();

  return (
    <footer className="border border-t-2 border-slate-950 mt-12 bg-black">
      <div className="lg:col-span-1 sm:col-span-full mx-12 lg:my-4 my-4 ">
        <h5 className="text-white block text-center font-inter font-reguler text-[8px] lg:text-[12px]">
          Copyright©{currentTime} PT. Eksak Guna Global. All rights reserved.
        </h5>
      </div>
    </footer>
  );
}
